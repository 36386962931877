/* =FONTS
-------------------------------------------------------------- */

@font-face {
  font-family: 'HelveticaNeueW23foSKYreg';
  font-style: normal;
  font-weight: 400;
  src: url('vendor_assets/fonts/sky/helveticaneuew23forsky-reg.eot');
  src: url('vendor_assets/fonts/sky/helveticaneuew23forsky-reg.eot?#iefix') format('embedded-opentype'),
  url('vendor_assets/fonts/sky/helveticaneuew23forsky-reg.woff2') format('woff2'),
  url('vendor_assets/fonts/sky/helveticaneuew23forsky-reg.woff') format('woff'),
  url('vendor_assets/fonts/sky/helveticaneuew23forsky-reg.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueW23foSKYbd';
  font-style: normal;
  font-weight: 700;
  src: url('vendor_assets/fonts/sky/HelveticaNeueW23foSKY-Bd.eot');
  src: url('vendor_assets/fonts/sky/HelveticaNeueW23foSKY-Bd.eot?#iefix') format('embedded-opentype'),
  url('vendor_assets/fonts/sky/HelveticaNeueW23foSKY-Bd.woff2') format('woff2'),
  url('vendor_assets/fonts/sky/HelveticaNeueW23foSKY-Bd.woff') format('woff'),
  url('vendor_assets/fonts/sky/HelveticaNeueW23foSKY-Bd.ttf') format('truetype');
}




@media screen and (max-width: 767px) {
.nav_right_content .cart_module .cart__items {
left: 0;
}
.cart-table .cart-actions .mr-auto {
margin-right: 0!important;
margin-top: 1rem;
}
/* #carouselExampleIndicators1 .carousel-control-prev, #carouselExampleIndicators1 .carousel-control-next{
  display:none;
}
#carouselExampleIndicators2 .carousel-control-prev, #carouselExampleIndicators2 .carousel-control-next{
  display:none;
}

.carouselExampleIndicators .carousel-control-prev, .carouselExampleIndicators .carousel-control-next{
  display:none;
} */

.footer__big img{
  max-width: 30%;
}

.cart-table .cart-actions .mr-auto {
  margin-right: auto!important;
  margin-left: 0!important;
  margin-top: 0;
  }
  


}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 736px) {

  .col-sm-6{
    width: 50%;
    text-align: center;
  }
  .card-img figure{
    height: 155px;
  }
  .card-imgb figure{
    height: auto;
  }

  .carousel-item h6, .product-grid h6{
    overflow: hidden;
    height: 50px;
  }

} 

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  .card-img figure{
    height: 292px;
  }
  .card-imgb figure{
    height: auto;
  }
  .card-imgb figure img{
    width: 100%;
  }

  .carousel-item h6, .product-grid h6{
    overflow: hidden;
    height: 50px;
  }

}


/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .minh{
    min-height: 96%;
  }
  .card-img figure{
    height: 225px;
  }
  .card-imgb figure{
    height: 478px;
  }

  .carousel-item h6, .product-grid h6{
    overflow: hidden;
    height: 58px;
  }

}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  .minh{
    min-height: 96%;
  }
  .card-img figure{
    height: 225px;
  }
  .card-imgb figure{
    height: 478px;
  }

  .carousel-item h6, .product-grid h6{
    overflow: hidden;
    height: 60px;
  }

}



body {
 
  font-family: 'HelveticaNeueW23foSKYreg', sans-serif !important;
}
.navbar-nav{
  direction:rtl;
  text-align: right;
}
.brand{
  max-width: 150px;
}
.footer_logo{
  max-width: 60%;
}
.maroof_logo{
  max-width: 30%;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'HelveticaNeueW23foSKYbd', sans-serif !important;
}

.herocover{
  text-align: right;
}

.herocover h1{
z-index: 7;
white-space: normal;
font-size: 48px;
line-height: 60px;
font-weight: 600;
color: rgb(32, 36, 40);
letter-spacing: 0px;
display: block;
visibility: inherit;
transition: none;
text-align: right;
border-width: 0px;
margin: 0px;
padding: 0px;
min-height: 0px;
min-width: 0px;
max-height: none;
max-width: none;
opacity: 1;
transform-origin: 50% 50% 0px;
transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}


.search_area i{
  cursor: pointer;
}

.top_bar .top_bar--info li span {
  margin-right: 0;
  margin-left: 8px;
}
.top_bar .top_bar--info li + li {
  margin-right: 2rem;
  margin-left: 0;
  }
.menu_area .nav_right_content .nav_right_module + .nav_right_module {
  padding-right: 1.6rem;
  padding-left: 0;
}

.menu_area .navbar {
  padding: 0rem;
  }

.navbar-brand{
  text-align: center;
}

.nav_right_content .cart_module .cart__items {
  left: -20px;
  right: auto;
}

.nav_right_content .cart_module .cart__items .items .item_info {
  margin-right: auto;
  margin-left: 10px;
  width: 100%;
  }

  .nav_right_content .cart_module .cart__items .items .item_thumb {
    margin-right: auto;
    margin-left: 15px;
}

.nav_right_content .cart_module .cart__items .cart_info a + a {
  margin-left: auto;
  margin-top: auto;
  margin-right: 10px;
  }

  .navbar-nav .dropdown-menu{
    left: auto;
    right: 0;
  }

.wrapper {
    display: block;
}

#menusidebar {
  /* don't forget to add all the previously mentioned styles here too */
  background: #FFFFFF;
  color: #202428;
  min-width: 310px;
  max-width: 310px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  /* top layer */
  z-index: 9999;
  transition: all 0.5s ease-in;

}

#menusidebar .sidebar-header {
  padding: 30px 20px;
  background: #f5f7fc;
  text-align: center;
}
.user-header {
  padding: 30px 20px;
  background: #ffffff;
  text-align: center;
}
#menusidebar .sidebar-header i, .user-header i{
font-size: 80px;
}

#menusidebar ul.components {
  padding: 20px 0;
  /* border-bottom: 1px solid #f5f7fc; */
}

#menusidebar ul li {
  border-bottom: 1px solid #ebeef8;
}

#menusidebar ul p {
  color: #202428;
  padding: 0 10px;
}

#menusidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: inline-block;
  width: 80%;
}
#menusidebar ul li a:hover {
  color: #413aa4;
  background: #fff;
}

#menusidebar ul li.active > a, a[aria-expanded="true"] {
  color: #202428;
  background: #FFFFFF;
 
}
#menusidebar ul ul a {
  font-size: 0.9em !important;
  padding-right: 30px !important;
  background: #f5f7fc;
  width: 100% !important;
  border-top: 1px solid #ebeef8;
  border-bottom: 0px !important;
}

#menusidebar ul ul li {
  border-bottom: 0px !important;
}



.overlay {
  display: none;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 998;
  opacity: 0;
  /* animate the transition */
  transition: all .3s;
}
/* display .overlay when it has the .active class */
.overlay.active {
  display: block;
  opacity: 1;
}

#dismiss {
  color: #413aa4;
  background-color: #fff;
  width: 35px;
  height: 35px;
  position: absolute;
  /* top right corner of the sidebar */
  top: 10px;
  left: 10px;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 50% 50%;
}

#dismiss:hover {
  color: #ffffff;
  background-color: #413aa4
}

.menu_arrow{
  width: 60px !important;
  text-align: center;
  border-right: 1px solid #ebeef8;
}

.out{
  border-top: 1px solid #e4e8ed;
}

.user-dashboard i{
  font-size: 95px;
}

.address p{
  font-size: 14px;
  line-height:0.1rm;
  margin: 0 0;
}
.user_details .card{
  min-height: 280px;
}

.select-basic:before{
  right: auto;
  left: 1rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "";
}

.product-details .product-info .product-actions form .total-item {
  margin-left: 2rem;
  }
  .product-details .product-info .product-actions form .total-item {
    margin-right: 0;
  }
  .product-details .product-info .product-actions form ul li {
    margin-left: 1.33333rem;
  }
  .product-details .product-info .product-actions form ul li {
    margin-right: 0;
  }
.product-details .product-info .product-category span {
  margin-left: 1.33333rem;
}
.product-details .product-info .product-category span {
  margin-right: 0;
}
.card-product figure figcaption ul li:last-child {
  margin-right: 0.66rem;
  }
.card-product figure figcaption ul li:last-child {
    margin-left: 0;
  }

  .alerts{
    margin: 12rem 0 12rem 0;
  }
  .alerts i{
    font-size: 65px;
    color: #e3eafc;
  }



  .pagination-area .pagination .page-item:last-child .page-link {
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;

    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
    line-height: 1.1;
  }


  .pagination-area .pagination .page-item:first-child .page-link {
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;

    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    line-height: 1.1;
  }

  .page-item:first-child .page-link {
    margin-left: -1px;
  }


  .category-widget i {
    float: left;
  }
 .category-widget ul li  {
    margin: 5px 0;
  }
  .category-widget ul ul a  {
    margin: 0 10px 0 0;
  }

 input[type=range].range-slider, .range-slider__wrap .range-slider__tooltip.range-slider__tooltip--bottom{
    direction: ltr;
  }


  .cart-table .cart-bottom form button {
    margin-left: auto;
    margin-right: 2rem;
    }
  .cart-table .cart-actions .mr-auto a:last-child {
    margin-left: auto;
    margin-right: .46667rem;
  }

  .checkout-wrapper .nav-item:before, .checkout-wrapper .nav-item:last-child:before {
    content: "\f104";
    }
    .checkout-wrapper .nav-item:first-child:before {
      content: "";
      }

.cart-table.cart-table--2 thead th:last-child {
  padding-left: 0;
}

.review .cart-single td + td, .review thead th + th{
  padding-left: 0;
}


.table-gray th, .checkout-wrapper .tab-content .method-1 .header, .checkout-wrapper .tab-content .method-2 .header, .checkout-wrapper .tab-content .method-1 .body{
  padding: 8px;
}


.form-check-input{
  width: 2.0rem;
  height: 1.3rem;
  right: -2.5rem;
  margin-top: 0;
}

.agreement .form-check {
  margin-right: 3rem;
  }

.center-c{
  margin-left: auto;
  margin-right: auto;
}

.copyright{
  direction: ltr;
}


.carousel-control-next .la-angle-right, .carousel-control-prev .la-angle-left{
  font-size: 64px;
}


.icon-box-nine {
  margin-bottom: 2rem;
  margin-top: 2rem;
  border-radius: 0.3rem;
  box-shadow: 0 ; 
  padding: 2rem 1.5rem;
  background: #ffffff;
  border: solid 1px #eaeef9;
  }

  .carousel-item img{
    max-height: 500px;
  }

  .block-header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    }

  .block_header_divider {
    flex-grow: 1;
    height: 2px;
    background: #ebebeb;
    margin-right: 16px;
    }

    .block_header_title{
    margin-bottom: 0;
    font-size: 20px;
    }


    .carousel-indicators li{
      background-color: #cccccc;
    }
    .carousel-indicators .active{
      background-color: #06b590;
    }

#carouselExampleIndicators1 .carousel-control-prev{
  color:#413aa4;
  font-size: 80px;
}
#carouselExampleIndicators1 .carousel-control-next{
  color:#413aa4;
  font-size: 80px;
}

#carouselExampleIndicators2 .carousel-control-prev{
  color:#413aa4;
  font-size: 80px;
}
#carouselExampleIndicators2 .carousel-control-next{
  color:#413aa4;
  font-size: 80px;
}
 
.carouselExampleIndicators .carousel-control-prev{
  color:#413aa4;
  font-size: 80px;
}
.carouselExampleIndicators .carousel-control-next{
  color:#413aa4;
  font-size: 80px;
}    



ul.attributeListImage li{
  /* display: inline; */
  padding:5px;
  margin: 5px;
}
ul.attributeListImage li img{
  padding: 1px 1px;
  border: 2px solid;
  border-color:#e4e8ed;
  cursor: pointer;
}

ul.attributeListText li{
  /* display: inline; */
  background-color: #f5f7fc;
  padding:8px;
  margin: 5px;
  border: 2px solid;
  border-color:#e4e8ed;
  cursor: pointer;
}

/* ul.attributeListColor li{
  display: inline;
  
} */

ul.attributeListColor li div{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
ul.attributeListColor li .contcolor{
  padding:1px 1px;
  margin: 10px auto; 
  border: 2px solid;
  border-color:#e4e8ed;
  cursor: pointer;
  width: 56px;
  height: 56px;
  border-radius: 50%;
}



.attributeContainer {
  border: 2px solid;
  border-color:#06b590;
  padding: 50;
  height: 200;
}


.closingmessage h1{
color:#413aa4;
}
.closingmessage i{
  font-size: 9em;
  color:#06b590;
}

.old-price {
  color: #9192a3;
  text-decoration: line-through;
  }

  .social .Demo__some-network{
    margin-left: 5px;
  }


  /* Categories */
#categories ul.components {
  padding: 0 0;
  /* border-bottom: 1px solid #f5f7fc; */
}

#categories ul li {
  border-bottom: 1px solid #ebeef8;
}

#categories ul p {
  color: #202428;
  padding: 0 10px;
}

#categories ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: inline-block;
  width: 80%;
}
#categories .menu_arrow{
  width: 20% !important;
  text-align: center;
  border-right: 1px solid #ebeef8;
}

#categories ul li a:hover {
  color: #413aa4;
  background: #fff;
}

#categories ul li.active > a, a[aria-expanded="true"] {
  color: #202428;
  background: #FFFFFF;
 
}
#categories ul ul a {
  font-size: 0.9em !important;
  padding-right: 30px !important;
  background: #f5f7fc;
  width: 100% !important;
  border-top: 1px solid #ebeef8;
  border-bottom: 0px !important;
}

#categories ul ul li {
  border-bottom: 0px !important;
}



.widget-wrapper table th {
  background-color: #f5f7fc;
  }


  .minus, .plus{
    font-size: 20px;
  }

  .item_remove{
    cursor: pointer;
  }

  .alert strong {
    font-weight: 700;
}
.table-gray th{
  font-size: 1.0rem!important;
  background-color: #f5f7fc;
}

.custom-control-label::before, 
.custom-control-label::after {
top: .8rem;
width: 1.25rem;
height: 1.25rem;
}

.colborder{
  border: 2px solid #e4e8ed;
}



/* Sliders */

#special .block_header_divider {
  margin-left: 80px;
  }


#special .carousel-control-next {
  right:auto;
  left: 46px;
  width: auto;
  top: -50px;
  }

#special .carousel-control-prev {
  width: auto;
  top: -50px;
  left: 15px;
  }
  
#special .carousel-control-prev span, #special .carousel-control-next span{
  background-color:  #413aa4;
  color:#ffffff;
  font-size: 30px;
  }

#special .carousel-control-prev span{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#special .carousel-control-next span{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
   




/* Banners */


#specialbanner .carousel-control-next {
  right:auto;
  left: 46px;
  width: auto;
  bottom: auto;
  top:5px;
  z-index: 1;
  }

#specialbanner .carousel-control-prev {
  width: auto;
  bottom: auto;
  top:5px;
  left: 15px;
  z-index: 1;
  }
  
#specialbanner .carousel-control-prev span, #specialbanner .carousel-control-next span{
  background-color:  #413aa4;
  color:#ffffff;
  font-size: 30px;
  }

#specialbanner .carousel-control-prev span{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#specialbanner .carousel-control-next span{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
   

.supportinfo ul li{
  direction: ltr;
}

.paymentimg .fileContainer{
  align-items: start;
}

.topborder{
  border-top: 1px solid #dee2e6;
}

.addtocart{
  background: #06b590;
  color: #FFFFFF;
  right:20px;
  top:20px;
  position:fixed;
  padding: 20px 20px;
  border-radius: 5px 5px;
}
