.fileContainer{
    padding: 0;
    margin: 0;
    box-shadow:none;
    background:transparent;
}
.fileContainer p{
    display: none;
}
.fileContainer .errorsContainer{
    display: none;
}
.fileContainer .chooseFileButton{
    padding: 9px;
    margin: 0;
    background:#ccc
}
span.css-1yn2e29-InputColor{
    padding: 0;
    width: 15px;
    height: 15px;
}